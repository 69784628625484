:root {
  --yellow: linear-gradient(180deg, #F8D49A -146.42%, #FAD79D -46.42%);
  --orange: #fca61f;
  --black: #242d49;
  --gray: #788097;
  --purple: linear-gradient(180deg, #BB67FF 0%, #C484F3 100%);
  --pink: #FF919D;
  --glass: rgba(255, 255, 255, 0.54);
  --boxShadow: 0px 19px 60px rgb(0 0 0 / 8%);
  --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
  --activeItem: #f799a354;
}
.App {
  /* padding: 0.5rem 3.5rem; */
  color: var(--black);
  background: linear-gradient(
    106.37deg,
    #ffe1bc 29.63%,
    #ffcfd1 51.55%,
    #f3c6f1 90.85%
  );
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
}
.AppGlass{
  display: grid;
  height:97%;
  width:97%;
  border-radius: 2rem;
  background-color: var(--glass);
  overflow: hidden;
  /* divide the class into grids */
  grid-template-columns: 11rem auto 20rem; 
  gap: 10px;
}

@media screen and (max-width:1200px) {
  .AppGlass{
    grid-template-columns: 10% 50% auto;
    overflow-y: scroll;
  }
}

@media screen and (max-width:768px){
  .AppGlass{
    grid-template-columns: 1fr; /*each component get one frame occupy full width */
  }
  .AppGlass::-webkit-scrollbar{
    display: none;
  }
}